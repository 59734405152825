import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { AppConfig } from '@dougs/core/config';
import { generateUuidV4 } from '@dougs/core/utils';
import { CreationModeUserData, User, UserDestroyParams, UserInfo } from '@dougs/user/dto';
import { MeCacheService } from '../cache/me-cache.service';

@Injectable({
  providedIn: 'root',
})
export class UserHttpService {
  constructor(
    private readonly http: HttpClient,
    private readonly meCacheService: MeCacheService,
  ) {}

  me(): Observable<User> {
    let user$ = this.meCacheService.getValue();

    if (!user$) {
      user$ = this.http
        .get<User>(`${AppConfig.settings.legacyApiServerUrl}/users/me`, {
          headers: {
            'X-Silent-User-Message': 'true',
          },
        })
        .pipe(shareReplay(1));

      this.meCacheService.setValue(user$);
    }

    return user$;
  }

  getUser(id: number): Observable<User> {
    return this.http.get<User>(`${AppConfig.settings.legacyApiServerUrl}/users/${id}`);
  }

  updateUser(user: User): Observable<User> {
    return this.http.post<User>(`${AppConfig.settings.legacyApiServerUrl}/users/${user.id}`, user);
  }

  updateUserEmail(user: User, email: string, emailConfirmation: string, password: string): Observable<User> {
    return this.http.post<User>(`${AppConfig.settings.legacyApiServerUrl}/users/${user.id}/actions/update-email`, {
      email,
      emailConfirmation,
      password,
    });
  }

  sendEmailConfirmation(user: User): Observable<User> {
    return this.http.post<User>(`${AppConfig.settings.legacyApiServerUrl}/auth/api/ask-email-confirmation`, {
      email: user.email,
    });
  }

  getUsersInfo(): Observable<UserInfo[]> {
    return this.http.get<UserInfo[]>(`${AppConfig.settings.legacyApiServerUrl}/users-info`);
  }

  getReferrerUsers(q?: string): Observable<User[]> {
    let httpParams: HttpParams = new HttpParams()
      .append('limit', 10)
      .append('orderBy', '["profile", "firstName"]')
      .append('suspended', false);

    if (q) {
      httpParams = httpParams.append('q', q);
    }

    return this.http.get<User[]>(`${AppConfig.settings.legacyApiServerUrl}/users`, {
      params: httpParams,
    });
  }

  getAssignableUsers(taskIds: number[], q?: string): Observable<User[]> {
    return this.http.post<User[]>(
      `${AppConfig.settings.legacyApiServerUrl}/tasks/actions/get-assignable-users-for-tasks`,
      {
        q,
        taskIds,
      },
    );
  }

  getUsers(hasFlags: string[] | null, q?: string): Observable<User[]> {
    let httpParams: HttpParams = new HttpParams()
      .append('limit', 10)
      .append('orderBy', '["profile", "firstName"]')
      .append('role', '$gte$accountant')
      .append('suspended', false);

    if (q) {
      httpParams = httpParams.append('q', q);
    }

    if (hasFlags) {
      hasFlags.forEach((flag) => {
        httpParams = httpParams.append('hasFlags', flag);
      });
    }

    return this.http.get<User[]>(`${AppConfig.settings.legacyApiServerUrl}/users`, {
      params: httpParams,
    });
  }

  removeFlag(user: User, flag: string): Observable<User> {
    return this.http.post<User>(`${AppConfig.settings.legacyApiServerUrl}/users/${user.id}/actions/remove-flag`, {
      flag,
    });
  }

  addFlag(user: User, flag: string): Observable<User> {
    return this.http.post<User>(`${AppConfig.settings.legacyApiServerUrl}/users/${user.id}/actions/add-flag`, {
      flag,
    });
  }

  updateRole(user: User, role: string): Observable<User> {
    return this.http.post<User>(`${AppConfig.settings.legacyApiServerUrl}/users/${user.id}/actions/update-role`, {
      role,
    });
  }

  updateTeam(user: User, teamId: number): Observable<User> {
    return this.http.post<User>(`${AppConfig.settings.legacyApiServerUrl}/users/${user.id}/actions/update-team`, {
      teamId,
    });
  }

  finalizeSignup(user: User): Observable<User> {
    return this.http.post<User>(
      `${AppConfig.settings.legacyApiServerUrl}/users/${user.id}/actions/finalize-signup`,
      {},
    );
  }

  finalizeOverride(user: User): Observable<User> {
    return this.http.post<User>(`${AppConfig.settings.signupApiServerUrl}/finalize-override`, {
      dougsUserId: user.id,
    });
  }

  signupInCreationMode({ email, password, ...userData }: CreationModeUserData): Observable<unknown> {
    const qsPolicies = userData.policiesAccepted ? '?policiesAccepted=true' : '';
    return this.http
      .post<void>(`${AppConfig.settings.legacyApiServerUrl}/auth/api/sign-up`, { email, password })
      .pipe(
        switchMap(() =>
          this.http.post(
            `${AppConfig.settings.legacyApiServerUrl}/init-signup/creation${qsPolicies}`,
            {
              signUserIn: true,
              company: {
                isCreated: false,
                metadata: {
                  creation: {
                    hasMultipleAssociates: userData.hasMultipleAssociates,
                    legalForm: userData.legalForm,
                    industry: userData.industry,
                    companyName: userData.companyName,
                  },
                },
              },
              profile: {
                firstName: userData.firstName,
                lastName: userData.lastName,
                phone: userData.phone,
              },
            },
            {
              headers: {
                'X-Client-Id': AppConfig.settings.legacyApiServerClientId,
              },
              responseType: 'text',
            },
          ),
        ),
      )
      .pipe(
        switchMap(() =>
          this.http.get(`${AppConfig.settings.legacyApiServerUrl}/users/me?refreshSession=true`, {
            headers: {
              'X-Silent-User-Message': 'true',
            },
          }),
        ),
      );
  }

  setUserProfile(activeUser: User, naturalPersonId: number, password: string): Observable<User> {
    return this.http.post<User>(`${AppConfig.settings.legacyApiServerUrl}/users/${activeUser.id}/actions/set-profile`, {
      naturalPersonId,
      password,
    });
  }

  resetPassword(email: string): Observable<string> {
    return this.http.post(
      `${AppConfig.settings.legacyApiServerUrl}/auth/api/ask-reset-password`,
      {
        email,
      },
      {
        responseType: 'text',
      },
    );
  }

  destroy(userId: number, params: UserDestroyParams): Observable<User> {
    let httpParams: HttpParams = new HttpParams();
    Object.entries(params).forEach(([key, value]) => {
      httpParams = httpParams.append(key, value);
    });
    return this.http.delete<User>(`${AppConfig.settings.legacyApiServerUrl}/users/${userId}`, {
      params: httpParams,
    });
  }

  abortDestroy(user: User): Observable<User> {
    return this.http.post<User>(`${AppConfig.settings.legacyApiServerUrl}/users/${user.id}/actions/abort-destroy`, {});
  }

  login(email: string, password: string): Observable<{ redirect: string; loginComplete: boolean }> {
    return this.http.post<{ redirect: string; loginComplete: boolean }>(
      `${AppConfig.settings.legacyApiServerUrl}/auth/api/login`,
      { email, password },
    );
  }

  changePassword(
    hash: string,
    { password, confirmPassword }: { password: string; confirmPassword: string },
  ): Observable<unknown> {
    return this.http.post(
      `${AppConfig.settings.legacyApiServerUrl}/auth/api/reset-password`,
      { hash, password, confirmPassword },
      { responseType: 'json' },
    );
  }

  initPassword(
    hash: string,
    { password, confirmPassword }: { password: string; confirmPassword: string },
  ): Observable<unknown> {
    return this.http.post(
      `${AppConfig.settings.legacyApiServerUrl}/init-password`,
      { hash, password, confirmPassword },
      { responseType: 'json' },
    );
  }

  ssoLogin(token: string): Observable<{ redirect: string }> {
    return this.http.post<{ redirect: string }>(`${AppConfig.settings.legacyApiServerUrl}/auth/api/sso/google`, {
      token,
    });
  }

  searchUsers(searchPayload: string, role: string, limit: number, offset: number): Observable<User[]> {
    const params: HttpParams = new HttpParams()
      .append('labels', 'true')
      .append('limit', limit)
      .append('offset', offset)
      .append('orderBy', 'profile')
      .append('orderBy', 'firstName')
      .append('role', `$lte$${role}`)
      .append('q', searchPayload);

    return this.http.get<User[]>(`${AppConfig.settings.legacyApiServerUrl}/users`, {
      params,
    });
  }

  createUserAndCompany(): Observable<User> {
    return this.http.post<User>(`${AppConfig.settings.legacyApiServerUrl}/create-user-and-company`, {
      email: `cogep${generateUuidV4()}v1@toto.com`,
      accountingFirmId: 4,
      accountingFirmOfficeId: 2,
      siren: '390702058',
      firstName: generateUuidV4(),
      lastName: generateUuidV4(),
    });
  }
}
